//==============================================================================
// COLORS
//==============================================================================

$color-black: black;
$color-white: white;
$color-stellar: #042320;
$color-space: #1f1d53;
$color-leaf: #586f2e;
$color-darkteal: #09282C;
//==============================================================================
// FONT SIZE
//==============================================================================

$font-size-8: 0.5rem; //8px
$font-size-12: 0.75rem; //12px
$font-size-14: 0.875rem; //14px
$font-size-16: 1rem; //16px
$font-size-18: 1.125rem; //18px
$font-size-20: 1.25rem; //20px
$font-size-26: 1.625rem; //26px
$font-size-30: 1.825rem; //30px
$font-size-36: 2.25rem; //36px

//==============================================================================
// GLOBAL VARIABLES
//==============================================================================
$header-height: 70px;
$max-width-text-centered: 880px;
$max-width-text-left: 530px;
$max-width-lg: 1200px;
$max-width-xl: 1440px;
