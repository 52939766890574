$color-comm-two-bg: #2850a0;
.community {
    &-page {
        background-color: $color-darkteal !important;
    }
    &__bg-image {
        position: absolute;
        top: 0;
        z-index: -1;
    }
    .footer {
        position: static;
        margin-top: 100px;
        padding-bottom: 80px;
        &__image {
            img {
                filter: brightness(100);
                -webkit-filter: brightness(100);
            }
        }
        &__text {
            color: $color-white;
        }
        svg path {
            fill: $color-white;
        }
    }
}

.section {
    &__community {
        &-one {
            .green-freak,
            .orange-freak {
                position: absolute;
                bottom: 0;
                top: 73%;
                height: var(--commOneHeight);
                min-height: var(--commOneHeight);
                max-height: var(--commOneHeight);
                > div {
                    height: 17.522%;
                    max-height: 17.522%;
                    display: inline-block;
                }
                img {
                    width: auto;
                    height: 100%;
                }
            }
            .green-freak {
                left: 2%;
            }

            .orange-freak {
                right: 41%;
            }
        }
        &-two,
        &-three {
            position: relative;
            .content-block__text-centered {
                position: absolute;
                top: 0;
                left: 50%;
                width: 100%;
                transform: translateX(-50%);
            }
        }
        &-two {
            position: relative;
            min-height: calc(var(--commTwoHeight) + 125px);
            margin-bottom: -2px;
            background-color: $color-comm-two-bg;
            .content-block__text-centered {
                margin-top: 50px;
                @include breakpoint(md) {
                    margin-top: 75px;
                }
            }
            .content-block__text-centered h2 {
                margin-bottom: 100px;
            }
            .content-block__text-centered p:not(:empty) {
                padding-bottom: 0;
            }
            .content-block__text-centered div {
                padding-top: 50px;
            }
        }
        &-three {
            .content-block__text-centered h2 {
                margin-bottom: 0;
                font-size: $font-size-18;
                @include breakpoint(sm) {
                    font-size: $font-size-26;
                }
                @include breakpoint(md) {
                    font-size: $font-size-36;
                }
                @include breakpoint(xl) {
                    margin-top: 75px;
                }
            }
            .content-block__text-centered.dark {
                top: 50%;
                transform: translate(-50%, -50%);
                h2 {
                    @include font-open-sans($font-size-26, 400, 1.62);
                    color: $color-black;
                    @include breakpoint(md) {
                        @include font-open-sans($font-size-36, 400, 1.62);
                    }
                }
            }
        }
    }
}

.community-page img {
    margin-left: auto;
    margin-right: auto;
}

// Animated SVG Objects

.fire-svg {
    position: absolute;
    left: 45%;
    max-height: var(--commOneHeight);
    width: 8%;
    bottom: 0;
    top: 43%;
}

.fire {
    &-smoke {
        animation: fireSmoke 4s ease-in-out infinite;
    }
    &-stroke {
        &-1,
        &-2,
        &-3,
        &-4,
        &-5,
        &-6,
        &-7,
        &-8 {
            animation: fireStroke 4s ease-in-out 0s infinite;
        }
        &-2 {
            animation-delay: 1s;
        }
        &-3 {
            animation-delay: 2s;
        }
        &-4 {
            animation-delay: 3s;
        }
        &-5 {
            animation-delay: 4s;
        }
        &-6 {
            animation-delay: 5s;
        }
        &-7 {
            animation-delay: 6s;
        }
        &-8 {
            animation-delay: 7s;
        }
    }
    &-outline {
        animation: fireOutline 2s ease-in-out infinite;
    }
    &-inner {
        animation: fireInner 5s ease-in-out infinite;
    }
    &-scale {
        animation: fireScale 1.5s ease-in-out infinite;
    }
}
