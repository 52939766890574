@mixin vfi($color: $color-black) {
    @include breakpoint(lg) {
        &:focus-visible {
            outline: 1px dotted $color !important;
        }
    }
}

/* Removes outline on focus */
@mixin no-outline {
    &:focus {
        outline: 0 !important;
    }
}

/* Remove hover state */
@mixin no-hover-state {
    text-decoration: none;
    &:hover,
    &:focus {
        text-decoration: none;
        color: inherit !important;
    }
}
/* Remove text decoration */
@mixin no-decoration {
    text-decoration: none;
    &:hover,
    &:focus {
        text-decoration: none !important;
    }
}

//==============================================================================
// Clearfix
//==============================================================================
@mixin clearfix() {
    &::after {
        display: block;
        content: "";
        clear: both;
    }
}

//==============================================================================
// FONTS
//==============================================================================
@mixin font-open-sans($font-size: $font-size-16, $font-weight: 400, $line-height: 1) {
    font-family: "Open Sans", sans-serif;
    font-size: $font-size;
    font-weight: $font-weight;
    line-height: $line-height;
}

@mixin font-roboto($font-size: $font-size-16, $font-weight: 400, $line-height: 1) {
    font-family: "Roboto", sans-serif;
    font-size: $font-size;
    font-weight: $font-weight;
    line-height: $line-height;
}

@mixin font-righteous($font-size: $font-size-16, $font-weight: 400, $line-height: 1) {
    font-family: "Righteous", cursive;
    font-size: $font-size;
    font-weight: $font-weight;
    line-height: $line-height;
}
