$main-img-width: 80%;
$main-img-width-lg: 37.2%;
$gradient-color-bg-top: #9ea0ff;
$gradient-color-bg-bottom: #ffd6eb;
.section {
    &__one {
        position: relative;
        &__image-bg {
            position: relative;
            z-index: -1;
            width: 100%;
            img {
                object-fit: cover;
                object-position: top;
                width: 100%;
                height: 100%;
            }
        }
    }
    &__two,
    &__three,
    &__four,
    &__faq {
        position: relative;
        height: 100%;
        @include breakpoint(md) {
            height: auto;
        }
        &__image-bg {
            position: relative;
            z-index: -1;
            width: 100%;
            height: 100%;
            @include breakpoint(md) {
                height: 65%;
            }
            @include breakpoint(lg) {
                height: auto;
            }
            img {
                object-fit: cover;
                object-position: center;
                width: 100%;
                height: 100%;
                @include breakpoint(md) {
                    object-position: top;
                }
            }
        }
    }
    &__four {
        min-height: calc(var(--roadmapHeight) + 100px);
        &__image-bg {
            @include breakpoint(md) {
                height: 100%;
            }
            @include breakpoint(xl) {
                img {
                    object-position: center;
                }
            }
        }
    }
    &__three {
        height: auto;
        text-align: center;
    }
    &__faq {
        height: auto;
        margin-bottom: 100px;
        @include breakpoint(md) {
            height: auto;
        }
        &__image-bg {
            position: absolute;
            top: 0;
            height: auto;
            z-index: 1;
        }
    }
    &__footer {
        position: relative;
        &__image-bg img {
            width: 100%;
        }
    }
    &__faq-footer {
        background: -webkit-linear-gradient(0deg, $gradient-color-bg-bottom 0%, $gradient-color-bg-top 100%);
        background: linear-gradient(0deg, $gradient-color-bg-bottom 0%, $gradient-color-bg-top 100%);
    }

    //Community
    &__community {
        &-one {
            position: relative;
        }
    }
}
