$transition-base: all 0.2s ease-in-out;
$transition-fade: opacity 0.15s linear;
$transition-collapse: height 0.35s ease;
$transition-collapse-width: width 0.35s ease;
$enable-transitions: true !default;
$enable-reduced-motion: true !default;
@mixin transition($transition...) {
    @if length($transition) == 0 {
        $transition: $transition-base;
    }

    @if length($transition) > 1 {
        @each $value in $transition {
            @if $value == null or $value == none {
                @warn "The keyword 'none' or 'null' must be used as a single argument.";
            }
        }
    }

    @if $enable-transitions {
        @if nth($transition, 1) != null {
            transition: $transition;
        }

        @if $enable-reduced-motion and nth($transition, 1) != null and nth($transition, 1) != none {
            @media (prefers-reduced-motion: reduce) {
                transition: none;
            }
        }
    }
}

//==============================================================================
// ANIMATIONS
//==============================================================================

@mixin animation-float($duration: 5s, $timing: ease-in-out, $iteration-count: infinite, $moving-position: -15px) {
    @keyframes float {
        0% {
            transform: translateY(0px);
        }
        50% {
            transform: translateY($moving-position);
        }
        100% {
            transform: translateY(0px);
        }
    }
    animation-name: float;
    animation-duration: $duration;
    animation-timing-function: $timing;
    animation-iteration-count: $iteration-count;
}

@keyframes butterfly-middle {
    0% {
        transform: translate(0, 0);
    }
    20% {
        transform: translate(30px, 20px);
    }
    40% {
        transform: translate(40px, -20px);
    }
    60% {
        transform: translate(20px, -40px);
    }
    80% {
        transform: translate(-60px, -60px) scale(0.7);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes butterfly-top {
    0% {
        transform: translate(0, 0);
    }
    20% {
        transform: translate(30px, 20px);
    }
    40% {
        transform: translate(40px, 20px);
    }
    60% {
        transform: translate(20px, 40px);
    }
    80% {
        transform: translate(60px, -60px);
    }
    100% {
        transform: translate(0, 0);
    }
}

@mixin animation-butterfly($animation-name, $duration: 15s, $timing: ease-in-out, $iteration-count: infinite) {
    animation-name: $animation-name;
    animation-duration: $duration;
    animation-timing-function: $timing;
    animation-iteration-count: $iteration-count;
}

// fire animations

@keyframes fireSmoke {
    0% {
        opacity: 0.6;
        transform: scale(1, 1) translate(0);
    }
    50% {
        opacity: 0.1;
        transform: scale(1.15, 0.9) translate(-5px, -20px);
    }
    100% {
        opacity: 0.6;
        transform: scale(1, 1) translate(0);
    }
}

@keyframes fireStroke {
    0% {
        opacity: 1;
        transform: scale(1, 1) translate(0, 150px);
    }
    50% {
        opacity: 0.8;
        transform: scale(1.15, 0.9) translate(-30px, -50px);
    }
    100% {
        opacity: 0;
        transform: scale(1, 1) translate(0, 0);
    }
}

@keyframes fireOutline {
    0% {
        opacity: 1;
        filter: brightness(1);
        transform: scale(1) translate(0px, 0);
    }
    50% {
        opacity: 0.9;
        filter: brightness(1.2);
        transform: scale(0.97) translate(3px, 0);
    }
    100% {
        opacity: 1;
        filter: brightness(0.95);
        transform: scale(1) translate(0px, 0);
    }
}

@keyframes fireInner {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    50% {
        opacity: 0.5;
        transform: scale(0.97);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fireScale {
    0% {
        opacity: 1;
        transform: scale(1.01);
    }
    50% {
        opacity: 0.5;
        transform: scale(0.97);
    }
    100% {
        opacity: 1;
        transform: scale(1.01);
    }
}
