/**
 * Global app/site styles
 * =================================
 */
 * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html {
    height: -webkit-fill-available;
    width: 100%;
    font-size: 1rem;
  }

  body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    margin: 0 auto !important;
    font-size: 1rem;
    background-color: $color-black !important;
    background: 0 0;
  }
  iframe {
    border: 0;
  }
  * {
    outline: 0;
  }

  a {
    text-decoration: none;
    transition: color ease 0.3s;
  }

  .no-click {
    pointer-events: none;
    user-select: none;
    cursor: default;
  }

  .image-align-right img {
    object-position: right;
  }
  .image-align-center img {
    object-position: center;
  }
  .image-align-left img {
    object-position: left;
  }

  .content {
    height: 100vh;
  }