.roadmap {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    margin: 30px auto;
    max-width: 992px;
    @include breakpoint(md) {
        margin: 0;
    }
    .desktop {
        display: flex;
        justify-content: space-between;
        padding-left: 30px;
        padding-right: 30px;
        .roadmap__item__20 {
            margin-top: 40px;
            margin-bottom: 50px;
        }
        .roadmap__item__50 {
            margin-top: 15.5em;
        }
        .roadmap__item__70 {
            margin-top: 11.5em;
        }
        .roadmap__item__100 {
            margin-top: 18em;
        }
        .roadmap__item__50,
        .roadmap__item__100 {
            h3 {
                text-align: right;
                margin-right: 10px;
            }
        }
        .roadmap__item__20,
        .roadmap__item__70 {
            h3 {
                text-align: left;
                margin-left: 10px;
            }
        }
    }
    .mobile {
        padding: 0 30px;
        .roadmap__item:not(:last-child) {
            margin-bottom: 50px;
        }
        .roadmap__item {
            h3 {
                text-align: center;
                margin-bottom: 10px;
            }
        }
    }
    &__heading {
        @include font-righteous($font-size-26, 400, 1.62);
        margin-top: 100px;
        margin-bottom: 50px;
        color: $color-white;
        text-align: center;
        @media (min-width: 430px) {
            margin-top: 50px;
        }
        @include breakpoint(md) {
            @include font-righteous($font-size-36, 400, 1.62);
        }
    }
    &__item {
        position: relative;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
        h3 {
            @include font-righteous($font-size-20, 400, 1.62);
            color: $color-white;
            @include breakpoint(md) {
                @include font-righteous($font-size-30, 400, 1.62);
            }
        }
        &__20 {
            h3 {
                @include font-righteous($font-size-26, 400, 1.62);
                @include breakpoint(md) {
                    @include font-righteous($font-size-36, 400, 1.62);
                }
            }
        }
        &__object {
            position: relative;
            img {
                width: 100%;
            }
            p {
                @include font-open-sans(($font-size-8 + 0.07), 400, 1.67);
                position: absolute;
                top: 0;
                padding: 6px 10px;
                color: $color-white;
                @include breakpoint(xs) {
                    padding: 10px 15px;
                    font-size: ($font-size-8 + 0.07);
                }
                @media (min-width: 375px) {
                    font-size: $font-size-12;
                    padding: 15px 20px;
                }
                @media (min-width: 450px) {
                    font-size: $font-size-14;
                }
                @include breakpoint(sm) {
                    font-size: $font-size-18;
                }
                @include breakpoint(lg) {
                    font-size: $font-size-16;
                }
            }
        }
    }
    &__timeline {
        &__image {
            position: relative;
            width: 160px;
            max-width: 160px;
        }
        &__freak {
            position: absolute;
            left: 53%;
            transform: translateX(-50%);
            width: 50px;
            top: -5px;
            img {
                width: 100%;
            }
            &.shown {
                bottom: var(--freakPosition);
                top: unset;
            }
        }
    }
}
