$main-img-width: 80%;
$main-img-width-md: 37.2%;
$main-img-width-lg: 500px;
$butterfly-width: 140px;
.object {
    &-wrapper {
        display: flex;
        justify-content: center;
        text-align: center;
        margin-top: -125px;
        @include breakpoint(sm) {
            margin-top: -200px;
        }
        @include breakpoint(lg) {
            margin-top: -270px;
        }
    }
    &__winter,
    &__spring {
        position: relative;
        display: none;
        @include breakpoint(md) {
            display: inline-block;
        }
    }
    &__winter {
        align-self: center;
        top: 25px;
        right: -30px;
        width: 167px;
        max-width: 167px;
        img {
            @include animation-float(10s, ease-in-out, infinite);
        }
    }
    &__spring {
        align-self: flex-end;
        top: 30px;
        right: -30px;
        width: 167px;
        max-width: 167px;
        @include breakpoint(lg) {
            right: -60px;
            width: 215px;
            max-width: 215px;
        }
        @include breakpoint(xl) {
            right: -100px;
        }
        @include breakpoint(xxl) {
            right: -150px;
        }
        img {
            @include animation-float(15s, ease-in-out, infinite);
        }
    }
    &__main {
        position: relative;
        display: inline-block;
        width: $main-img-width;
        max-width: $main-img-width;
        @include breakpoint(md) {
            width: $main-img-width-md;
            max-width: $main-img-width-md;
        }
        @include breakpoint(lg) {
            width: $main-img-width-lg;
            max-width: $main-img-width-lg;
        }
        img {
            @include animation-float(3s, ease-in-out, infinite, -15px);
        }
    }
    &__butterfly-top,
    &__butterfly-middle {
        position: absolute;
        opacity: 0.6;
        width: $butterfly-width;
        max-width: $butterfly-width;
        @include breakpoint(lg) {
            opacity: 1;
        }
        img {
            width: 100%;
            transform-style: preserve-3d;
        }
    }
    &__butterfly-middle {
        left: 30px;
        top: 54%;
        transform: translateY(-50%);
        @include breakpoint(lg) {
            left: 60px;
        }
        @include breakpoint(xl) {
            left: 100px;
        }
        img {
            @include animation-butterfly(butterfly-middle);
        }
    }
    &__butterfly-top {
        top: 15%;
        right: 60px;
        transform: matrix(-0.95, 0.3, 0.3, 0.95, 0, 0);
        @include breakpoint(sm) {
            right: 50px;
        }
        @include breakpoint(lg) {
            right: 120px;
        }
        @include breakpoint(xl) {
            right: 240px;
        }
        img {
            @include animation-butterfly(butterfly-top);
        }
    }
}
