.nav {
    @include breakpoint(lg) {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex: 0 0 100%;
        justify-content: center;
        &.collapse {
            display: flex !important;
            + .bg-filter {
                opacity: 1 !important;
            }
        }
    }
    &.collapse + .bg-filter {
        opacity: 0;
    }
    &.show + .bg-filter {
        opacity: 1;
    }
    &__left,
    &__right {
        height: $header-height;
        max-height: $header-height;
        display: flex;
        align-items: center;
        justify-content: center;
        ul {
            margin-top: 0;
            margin-bottom: 0;
            padding-left: 0;
            list-style: none;
            display: flex;
            flex-flow: row wrap;
        }
        li {
            @include font-open-sans($font-size-16, 600, 1);
            margin-right: 15px;
            padding-right: 15px;
            border-right: 1px solid $color-white;
            &:last-child {
                border-right: none;
                margin-right: 0;
                padding-right: 0;
            }
        }
        a {
            @include font-open-sans($font-size-14, 600, 1);
            color: $color-white;
            text-decoration: none;
            cursor: pointer;
            @include breakpoint(md) {
                @include font-open-sans($font-size-16, 600, 1);
            }
        }
    }
    &__mobile {
        ul {
            padding-left: 60px;
            padding-right: 60px;
            margin-top: 80px;
            margin-bottom: 70px;
            text-align: center;
        }
        ul li {
            list-style: none;
            margin-bottom: 15px;
        }
        ul li a {
            @include font-open-sans($font-size-20, 600, 1.62);
            color: $color-white;
            cursor: pointer;
            text-decoration: none;
            @include breakpoint(md) {
                @include font-open-sans($font-size-26, 600, 1.62);
            }
        }
    }
}

.menu-button,
.menu-button::before,
.menu-button::after {
    display: block;
    background-color: #fff;
    position: absolute;
    height: 4px;
    width: 30px;
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    border-radius: 2px;
}

.menu-button::before {
    content: "";
    margin-top: -8px;
}

.menu-button::after {
    content: "";
    margin-top: 8px;
}

.hamburger-lines {
    @extend %btn-muted;
    display: block;
    padding: 0;
    height: 26px;
    width: 32px;
    position: absolute;
    top: 30px;
    left: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include breakpoint(lg) {
        display: none;
    }
}

.hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: $color-white;
}

.hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
}

.hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
}

.hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
}

.hamburger-lines.expanded .line1 {
    transform: rotate(45deg);
}

.hamburger-lines.expanded .line2 {
    transform: scaleY(0);
}

.hamburger-lines.expanded .line3 {
    transform: rotate(-45deg);
}

.mobile-logo {
    position: absolute;
    right: 0;
    width: 45px;
    margin-right: 15px;
    margin-top: 15px;
    transition: opacity 0.3s ease-in-out;
    @include breakpoint(lg) {
        display: none;
    }
    // &.expanded {
    //     opacity: 0;
    // }
    // &.collapsed {
    //     opacity: 1;
    // }
}
