$logo-width: 150px;
$header-bg: linear-gradient(180deg, rgba(0, 0, 0, 0.55) -25.83%, rgba(0, 0, 0, 0) 88.33%);
header {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    z-index: 1;
}

.logo {
    width: ($logo-width - 30);
    margin: 0 auto;
    @include breakpoint(lg) {
        width: $logo-width;
        margin: 15px 40px 0;
    }
    &:hover {
        cursor: pointer;
    }
    img {
        min-width: 100%;
        width: 100%;
        min-height: 100%;
        height: 100%;
    }
    &-desktop {
        display: none;
        @include breakpoint(lg) {
            display: block;
        }
    }
}

.bg-filter {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: $header-bg;
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);
    background-blend-mode: overlay;
    z-index: -1;
    transition: opacity 0.3s ease-in-out;
    @include breakpoint(lg) {
        height: $header-height;
    }
    @supports (-webkit-touch-callout: none) {
        backdrop-filter: blur(40px) contrast(100%) brightness(0.5);
        -webkit-backdrop-filter: blur(40px) contrast(100%) brightness(0.5);
        background-color: transparentize($color: $color-black, $amount: 0.5);
    }
}
