//==============================================================================
// MEDIA QUERY BREAKPOINTS
//==============================================================================
$breakpoint-xxs: 280px;
$breakpoint-xs: 375px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1200px;
$breakpoint-desktop: 1340px;

$breakpoints: (
    xs: 320px,
    sm: 576px,
    md: 768px,
    lg: 1025px,
    xl: 1200px,
    xxl: 1440px,
);

//rule: min-width or max-width
@mixin breakpoint($bp, $rule: min-width) {
    @media screen and (#{$rule}: map-get($breakpoints, $bp)) {
        @content;
    }
}
