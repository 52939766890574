$footer-logo-size: 133px;
.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    &__image {
        width: $footer-logo-size;
        max-width: $footer-logo-size;
        margin: 0 auto;
        img {
            width: 100%;
        }
    }
    &__text {
        @include font-roboto($font-size-14, 400, 1.142);
        margin: 30px 0;
        color: $color-leaf;
    }
    &__social {
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
        &__boat {
            margin-right: 10px;
        }
        &__twitter {
            margin-left: 10px;
        }
    }
}
