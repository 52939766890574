$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out !default;
$accordion-transition: $btn-transition, border-radius 0.15s ease !default;
.accordion {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 50px 30px;
    max-width: 550px;
    z-index: 1;
    @include breakpoint(md) {
        padding-top: 80px;
    }
    @include breakpoint(lg) {
        max-width: 808px;
        padding-top: 100px;
    }
    @include breakpoint(xxl) {
        padding-top: 130px;
    }
    &-item:not(:last-child) {
        margin-bottom: 10px;
    }
    &-button {
        @include font-righteous($font-size-16, 400, 1.62);
        @include transition($accordion-transition);
        position: relative;
        width: 100%;
        padding: 5px 15px;
        display: flex;
        align-items: center;
        color: $color-space;
        background-color: transparentize($color: $color-white, $amount: 0.78);
        overflow-anchor: none;
        text-align: left;
        border: 0;
        margin-bottom: 0;
        border: 1px solid rgba(145, 11, 255, 1);
        cursor: pointer;
        @include breakpoint(sm) {
            @include font-righteous($font-size-20, 400, 1.62);
        }
        @include breakpoint(lg) {
            @include font-righteous($font-size-26, 400, 1.62);
        }
        &:not(.collapsed) {
            &::after {
                content: "-";
            }
        }

        &::after {
            @include transition(transform 0.2s ease-in-out);
            margin-left: auto;
            margin-right: 10px;
            content: "+";
        }

        &:hover {
            z-index: 2;
        }

        &:focus {
            z-index: 3;
            outline: 0;
        }
    }
    &-body {
        @include font-open-sans($font-size-14, 400, 1.67);
        padding: 30px 15px;
        color: $color-space;
        background-color: transparentize($color: $color-white, $amount: 0.78);
        border: 1px solid rgba(145, 11, 255, 1);
        border-top: 1px solid transparent;
        @include breakpoint(lg) {
            @include font-open-sans($font-size-18, 400, 1.67);
        }
    }
    &__heading {
        @include font-righteous($font-size-26, 400, 1.62);
        margin-top: 0;
        margin-bottom: 50px;
        color: $color-white;
        text-align: center;
        @include breakpoint(md) {
            @include font-righteous($font-size-36, 400, 1.62);
        }
    }
}

.fade {
    @include transition(opacity 0.15s linear);

    &:not(.show) {
        opacity: 0;
    }
}

.collapse {
    &:not(.show) {
        display: none;
    }
}

.collapsing {
    height: 0;
    overflow: hidden;
    @include transition(height 0.35s ease);

    &.collapse-horizontal {
        width: 0;
        height: auto;
        @include transition(width 0.35s ease);
    }
}
