.content-block {
    &__text-centered {
        max-width: $max-width-text-centered;
        margin: 0 auto;
        padding: 0 30px;
        p:not(:empty) {
            @include font-righteous($font-size-18, 400, 1.61);
            padding-bottom: 50px;
            color: $color-white;
            text-align: center;
            @include breakpoint(md) {
                @include font-righteous($font-size-26, 400, 1.61);
            }
        }
        h2 {
            @include font-righteous($font-size-26, 400, 1.62);
            margin-bottom: 50px;
            color: $color-white;
            text-align: center;
            @include breakpoint(md) {
                @include font-righteous($font-size-36, 400, 1.62);
            }
        }
    }
    &__text-left-aligned {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 30px;
        padding-bottom: 30px;
        padding-left: 30px;
        padding-right: 30px;
        text-align: center;
        text-shadow: 2px 1px 2px transparentize($color: $color-black, $amount: 0.05);
        @include breakpoint(lg) {
            width: 50%;
            max-width: $max-width-text-left;
            margin-left: 15%;
            text-shadow: none;
            text-align: left;
        }
        h2 {
            @include font-righteous($font-size-26, 400, 1.62);
            margin-bottom: 10px;
            color: $color-white;
            @include breakpoint(md) {
                @include font-righteous($font-size-36, 400, 1.62);
            }
        }
        p:not(:empty) {
            @include font-open-sans($font-size-16, 400, 1.62);
            color: $color-white;
            @include breakpoint(md) {
                @include font-open-sans($font-size-18, 400, 1.62);
            }
        }
    }
}
