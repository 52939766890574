//==============================================================================
// BUTTONS/LINKS
//==============================================================================
%btn-muted {
    background: transparent;
    border: 0;
  }
  
  %link-muted {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }

  %reset-input-style {
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
  }