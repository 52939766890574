// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
$button-primary-bg-color: linear-gradient(180deg, #41d3f3 0%, #0babde 100%);
$button-color-bg-disabled: #95a5a6;
$button-color-disabled: #7f8c8d;
$button-type: (
    primary: (
        border: 1px solid transparent,
        color: $color-white,
        background-color: $button-primary-bg-color,
    ),
);

$button-size: (
    default: (
        padding: 10px 50px,
        font-size: $font-size-20,
        line-height: 1,
    ),
);

//function to get deep level mapped keys
@function map-deep-get($map, $keys...) {
    @each $key in $keys {
        $map: map-get($map, $key);
    }

    @return $map;
}

@mixin btn($btn-type, $btn-size) {
    display: inline-block;
    padding: map-deep-get($button-size, $btn-size, padding);
    color: map-deep-get($button-type, $btn-type, color);
    background: map-deep-get($button-type, $btn-type, background-color);
    border: 1px solid transparent;
    font-family: "Righteous", cursive;
    font-size: map-deep-get($button-size, $btn-size, font-size);
    line-height: map-deep-get($button-size, $btn-size, line-height);
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    outline: 0;
    outline-offset: 0;
    border-radius: 100px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;

    &:hover,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        @if ($btn-type==primary) {
            background: $button-primary-bg-color;
            color: $color-white;
        }
    }

    &:focus,
    &:focus:not(:focus-visible) {
        @include breakpoint(lg) {
            outline: 1px dotted lighten($color-black, 50%);
        }
        @if ($btn-type==primary) {
            color: $color-white;
        }
    }
}

button,
a {
    transition: background-color ease-in-out 0.3s, color ease-in-out 0.3s, border-color ease-in-out 0.3s;

    &:disabled,
    &.disabled {
        //used important to override hover effects
        color: $button-color-disabled !important;
        background: $button-color-bg-disabled !important;
        cursor: default;
    }
}

.btn-primary {
    @include btn(primary, default);
}
