$collection-box-size: 350px;
.collection {
    &__heading {
        @include font-righteous($font-size-26, 400, 1.62);
        margin-top: 30px;
        margin-bottom: 30px;
        color: $color-white;
        text-align: center;
        @include breakpoint(md) {
            @include font-righteous($font-size-36, 400, 1.62);
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }
    &__item {
        max-width: $max-width-lg;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        row-gap: 30px;
        @include breakpoint(md) {
            padding: 0 30px;
            grid-template-columns: repeat(2, 1fr);
            row-gap: 60px;
        }
        @include breakpoint(lg) {
            grid-template-columns: repeat(3, 1fr);
            row-gap: 30px;
        }
        @include breakpoint(xl) {
            padding: 0;
            grid-template-columns: repeat(4, 1fr);
            row-gap: 10px;
        }
        @include breakpoint(xxl) {
            max-width: $max-width-xl;
        }
        &__box {
            width: ($collection-box-size - 60);            
            height: $collection-box-size;
            display: flex;
            align-items: center;
            justify-content: center;
            justify-self: center;
            @include breakpoint(xxl) {
                width: $collection-box-size;
            }
        }
        &__image {
            height: 300px;
            max-height: 300px;
            img {
                height: 100%;
                margin: 0 auto;
            }
        }
    }
}
