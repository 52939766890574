$minting-max-width: 552px;
$minting-max-height: 406px;
.minting {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    margin: 30px 0;
    @include breakpoint(md) {
        margin: 0;
    }
    &__content {
        position: relative;
        max-width: $minting-max-width;
        max-height: $minting-max-height;
        margin: 0 auto;
        padding: 0 30px;
        @include breakpoint(sm) {
            min-width: $minting-max-width;
            width: $minting-max-width;
            min-height: $minting-max-height;
            height: $minting-max-height;
        }
        img {
            width: 100%;
            height: 100%;
        }
    }
    &__wrapper {
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        @include breakpoint(xs) {
            top: 25px;
        }
        @include breakpoint(sm) {
            top: 50px;
        }
        h2 {
            @include font-righteous($font-size-20, 400, 1.62);
            color: $color-white;
            text-transform: uppercase;
            @include breakpoint(xs) {
                @include font-righteous($font-size-26, 400, 1.62);
            }
            @include breakpoint(sm) {
                @include font-righteous($font-size-36, 400, 1.62);
            }
        }
    }
    &__head {
        margin-bottom: 15px;
        @include breakpoint(xs) {
            margin-bottom: 30px;
        }
        @include breakpoint(sm) {
            margin-bottom: 60px;
        }
        p {
            @include font-open-sans($font-size-12, 400, 1.62);
            color: $color-white;
            @include breakpoint(xs) {
                font-size: $font-size-14;
            }
            @include breakpoint(sm) {
                font-size: $font-size-16;
            }
        }
    }
    &__input {
        input {
            @include font-open-sans($font-size-18, 700, 1.62);
            width: 109px;
            height: 30px;
            background-color: $color-white;
            color: $color-stellar;
            border: none;
            border-radius: 100px;
            text-align: center;
            @media (min-width: $breakpoint-xs) {
                height: 40px;
            }
            @include breakpoint(sm) {
                @include font-open-sans($font-size-26, 700, 1.62);
            }
        }
        span {
            @include font-open-sans($font-size-16, 400, 1.62);
            display: block;
            margin-top: 15px;
            margin-bottom: 25px;
            color: $color-white;
        }
    }
    &__button {
        margin-top: 15px;
        text-transform: uppercase;
        @media (min-width: 300px) {
            margin-top: 30px;
        }
        @media (min-width: 350px) {
            margin-top: 60px;
        }
        @media (min-width: 400px) {
            margin-top: 80px;
        }
        @media (min-width: 420px) {
            margin-top: 100px;
        }
        @media (min-width: 430px) {
            margin-top: 0;
        }
        @include btn(primary, default);
    }
}
